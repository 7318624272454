<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small" @submit.native.prevent>
      <el-form-item label="公司名称" prop="title">
        <el-input v-model="info.title" placeholder="请输入公司名称"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone">
        <el-input v-model="info.phone" placeholder="请输入联系电话"></el-input>
      </el-form-item>
      <el-form-item label="地址" prop="address">
        <el-input v-model="info.address" placeholder="请输入地址"></el-input>
      </el-form-item>
      <el-form-item label="邮编" prop="zip_code">
        <el-input v-model="info.zip_code" placeholder="请输入邮编"></el-input>
      </el-form-item>
      <el-form-item label="传真" prop="fax">
        <el-input v-model="info.fax" placeholder="请输入传真"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="reBack()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

export default {
  data() {
    return {
      menuList: [],
      info: {
        id: 0,
        title: '',
        phone: '',
        address: '',
        zip_code: '',
        fax: '',
      },
      source_domain: this.config.SOURCE_DOMAIN,
      rules: {
        title: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
      }
    };
  },
  created() {
    if(this.$route.query.id) {
      this.info.id = this.$route.query.id
      this.getInfo()
    }
  },
  components: {
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    getInfo() {
      var that = this
      this.$api.about.aboutContactInfo({id: that.info.id}, function (res) {
        if(res.errcode == 0) {
          that.info = res.data
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = that.info
          this.$api.about.aboutContactEdit(param,function (res) {
            if(res.errcode == 0) {
              that.success(res.errmsg)
              that.reBack()
            } else {
              that.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
</style>
